import { isEqual } from "lodash";
import { useState, useEffect } from "react";
import { filterExpiredPromotions } from "../helpers/hooks-helpers";

/**
 * Hook for filtering expired events/promotions in a stateful manner.
 *
 * @param {array} data
 *   An array of event/promotion objects.
 * @param {callable} filterCallback
 *   (Optional) The callback to be used to filter the items.
 *   Defaults to `filterExpiredPromotions`
 *
 * @returns array
 *   Returns the filtered array of items.
 */
export const useCurrentFilter = (data, filterCallback) => {
  const callback = filterCallback || filterExpiredPromotions;
  const [events, setEvents] = useState(data || [])
  useEffect(() => {
    const currentEvents = events.filter(callback);
    // Only update the state if the data changed after the filter was applied.
    if (!isEqual(currentEvents, events)) {
      setEvents(currentEvents);
    }
  }, [events]);
  return events;
};
